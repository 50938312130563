<template>
   <v-app id="404">
      <v-container fluid fill-height>
        <v-layout align-center justify-center row>
            <div class="mr-3 hidden-sm-and-down">
                <img :src="getImgNotFound" alt="">
            </div> 
            <div class="text-md-center">
                <h1>404</h1>
                <h2 class="my-3 headline ">Page not found.</h2>
                <div>
                <v-btn color="primary" @click="goHome">Go home</v-btn>
                </div>  
            </div>
        </v-layout>
      </v-container>
  </v-app> 
</template>

<script>
import imgNotFound from '@/assets/404.svg'
export default {
    computed: {
        getImgNotFound() {
            return imgNotFound
        }
    },

    methods: {
        goHome () {
            //return this.$router.push({ path: '/' });
            return this.$router.push({ name: 'invoices'})
        }
    }
}
</script>

<style scoped>
    h1 {
        font-size: 150px;
        line-height: 150px;
        font-weight: 700;
        color: #252932;
        text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;    
    }
</style>


